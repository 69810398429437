.header_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  h2 {
    margin-bottom: 0;
  }
}
