:root {
  --primary-color: rgb(62, 100, 255);
  --complete-color: #27aa80;
  --text-color: #172b4d;
  --disabled-color: #fad6d6;
  --background-color: #f5eaea;
}

button,
input {
  padding: 4px;
  border: 1px solid var(--disabled-color);
}

button {
  outline: none;
  background: transparent;
  border-radius: 5px;
  color: var(--primary-color);
  transition: all ease 0.8s;
  cursor: pointer;
}

button.active {
  color: var(--primary-color);
}

button.active:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 50%;
  padding-top: 4px;
  border-bottom: 1px solid var(--primary-color);
}

input:focus {
  outline: none;
}

select {
  outline: none;
  height: 40px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.item {
  font-size: 15px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  position: relative;

  .item-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.item:hover {
  cursor: pointer;
}

.item-title {
  font-weight: 600;
  font-size: 16px;
}

.item-status {
  text-align: right;
}

.color-bar {
  width: 40px;
  height: 10px;
  border-radius: 5px;
}

.drop-wrapper {
  flex: 10;
  width: 100%;
  height: 100%;
}

.col-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 5px;
}

.col-header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 0;
}

.col {
  min-height: 300px;
  max-width: 300px;
  width: 300px;
}

.highlight-region {
  background-color: yellow;
  min-height: 300px;
  max-width: 300px;
  width: 300px;
}

.page-header {
  background-color: #054f7c;
  padding: 20px;
  color: white;
  font-size: 30px;
  flex: 1 100%;
  margin-top: 0;
  text-align: center;
}

.modal {
  border-radius: 2px;
  margin: 48px 0 80px;
  min-height: 450px;
  width: 800px;
  outline: none;
  padding: 20px;
}

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  // background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  width: 100%;

  .container {
    width: 50%;
    height: 50%;
    background-color: var(--primary-color);
    padding: 16px;
    color: white;
    border-radius: 10px;
    position: relative;
  }

  .loading-container {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
  }
}

.close-btn-ctn {
  display: flex;
}

.close-btn {
  height: 40px;
  width: 35px;
  font-size: 20px;
  color: #031d2c;
  border: none;
  border-radius: 25px;
}

.close-btn:hover {
  background-color: #dcdcdc;
}

.input-wrapper {
  width: 100%;
}

.item-title-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
