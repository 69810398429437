.checkbox-wrapper {
  display: flex;

  input {
    margin-right: 10px;
  }
}

.checkbox-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
}

.btn {
  color: white;
  display: flex;
}

.title {
  margin-left: 10px;
}

.btn-wrapper {
  display: flex;
}

.input {
  width: 50%;
  background-color: white;
  border-radius: 10px;
}
