.wrapper {
  background-color: var(--primary);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;

  h5 {
    color: white;
    font-size: 24px;
  }

  .btn-switch {
    display: flex;
    justify-content: center;
  }
  .nav-switch {
    display: flex;

    li {
      width: 50%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button {
        width: 100%;
      }
    }

    .separate {
      width: 30px;
      // text-align: center;
      border-bottom: 3px solid transparent;

    }

    .active {
      border-bottom: 3px solid var(--primary);
      border-radius: 10px;
    }
  }

  .content-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    .content {
      background-color: var(--white);
      height: 100vh;
      padding: 16px;
      width: 100%;
    }
  }
}

.container {
  width: 100%;
  display: flex;
}

.content {
  flex: 1;
}
